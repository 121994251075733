.App {
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Sorts Mill Goudy', serif; */
  font-weight: 500;
  /* font-family: 'Crimson Text', serif; */
  /* font-family: 'PT Serif', serif; */
}

.App-sans{
  font-family: 'Proza Libre', sans-serif;
  font-weight: 400;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.enter-btn{
  background: black;
  color: white;
  border: 2px solid black;
  transition: 0.3s;
  font-size: 1.5rem;
}

.enter-btn:hover{
  background: #bae6fd;
  color: black;
  border: 2px solid #bae6fd;
}

.enter-form{
  font-size: 1.5rem;
}

.error-modal{
  /* background: rgba(255,255,255,0.8); */
  padding: 2vh 40vw;
}

.icon{
  transition:0.3s;
}

.icon:hover{
  cursor: pointer;
  transform: rotate(-0.12turn);
}

.active-link{
  border-bottom: 2px solid black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
